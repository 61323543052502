<template>
  <div  class="col-12 small_font p-0 mt-5">
    <div class="row mx-0 mb-4 justify-content-center">
      <div class="col-auto text-center gold_heading mb-2">
        Branches Offices / Donation Points
        <div class="row m-0 justify-content-center">
          <div class="col-6 pt-4 text-center">
            <div class="contact_heading_underline"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-lg" >
      <div class="row m-0">
        <div class="col-md-6 mb-4 h-100" v-for="branch, key in branches" :key="key">
          <div class="row m-0 card_item_small">
            <div class="col-md-4 p-0">
              <div class="row m-0 holder h-100">
                <div class="col-12 p-0 d-none d-md-block">
                  <iframe width="600" height="450" frameborder="0" style="border:0; width: 100%; height: 100%; border-top-left-radius:15px; border-bottom-left-radius:15px;" allowfullscreen="" loading="lazy" :src="`https://maps.google.com/maps?&amp;q=${getMap(branch.physicalAddress)}&amp;output=embed`"></iframe>
                </div>
                <div class="col-12 p-0 d-md-none">
                  <iframe width="600" height="450" frameborder="0" style="border:0; width: 100%; height: 100%; border-top-left-radius:15px; border-top-right-radius:15px;" allowfullscreen="" loading="lazy" :src="`https://maps.google.com/maps?&amp;q=${getMap(branch.physicalAddress)}&amp;output=embed`"></iframe>
                </div>
              </div>
            </div>
            <div class="col p-3">
              <div class="row m-0 align-items-center mb-2">
                <div class="col-1 p-0 gold-text h-100 ">
                  <IconLocation />
                </div>
                <div class="col-11 gold_heading">
                  {{branch.name}}
                </div>
              </div>
              <div class="row m-0">
                <div class="col-12 smallheading">
                  {{branch.name}} Office
                </div>
                <div class="col-12 mt-2 mb-2">
                  <div class="row m-0 align-items-start lh-1">
                    <div class="col-1 p-0 gold-text">
                      <IconLocation size="size16" />
                    </div>
                    <div class="col-11 bold">
                      {{branch.physicalAddress}}
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <div class="row m-0 align-items-center">
                    <div class="col-1 p-0 gold-text">
                      <IconPhone size="size16" />
                    </div>
                    <div class="col-11 bold">
                      {{branch.telephone}}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row m-0 align-items-center">
                    <div class="col-1 p-0 gold-text">
                      <IconMail size="size16" />
                    </div>
                    <div class="col-11 bold">
                      {{branch.email}}
                    </div>
                  </div>
                </div>
                <div class="col-12 green-text mt-3">
                  <Button color="transparent" btnText="Call" icon2="arrow" size="small" class="me-2" @buttonClicked="call(branch.telephone)">
                    <template v-slot:iconStart>
                      <IconPhone color="green" size="size16" />
                    </template>
                  </Button>
                  <Button color="transparent" btnText="Directions" icon2="arrow" size="small">
                    <template v-slot:iconStart>
                      <IconMail color="green" size="size16" />
                    </template>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconPhone: defineAsyncComponent(() => import('@/components/icons/IconPhone.vue')),
    IconMail: defineAsyncComponent(() => import('@/components/icons/IconMail.vue')),
    IconLocation: defineAsyncComponent(() => import('@/components/icons/IconLocation.vue'))
  },
  name: 'DonationPoints',
  methods: {
    call (val) {
      window.location.href = 'tel:' + val
    },
    getMap (val) {
      const embed = encodeURIComponent(val)
      return embed
    }
  },
  computed: {
    ...mapGetters([
      'branches'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.small_font {
  font-size: 0.8rem;
}
.gold_heading {
  font-family: "quicksand_bold", Sans-serif;
  font-weight: 700;
  letter-spacing: 2.4px;
  color: var(--gold-color);
  font-size: 1rem;
  text-transform: uppercase;
}
.contact_heading_underline {
  border-bottom: 2px solid var(--gold-color);
}

.smallheading {
  color: var( --green-color );
  font-family: "quicksand_bold", Sans-serif;
  font-size: 1.7rem;
  font-weight: bold;
}

.card_item_small {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 5px 5px 15px 0px rgb(0 0 0 / 9%);
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
  color: #333;
}
</style>
